<template>
  <template v-if="$isMobile()">
    <Carousel :autoplay="autoplay" :wrap-around="true">
      <Slide v-for="slide in images" :key="slide">
        <img class="dvSlider" :src="slide.name" />
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </template>
  <template v-else>
    <Carousel
      :items-to-show="3"
      :autoplay="autoplay"
      :wrap-around="true"
      pauseAutoplayOnHover
    >
      <Slide v-for="slide in images" :key="slide">
        <img class="dvSlider" :src="slide.name" />
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </template>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  props: {
    images: Array,
    autoplay: {
      type: Number,
      default: 3000,
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
};
</script>
<style lang="scss" scoped>
.dvSlider {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
</style>
