<template>
  <div id="fullpage-container">
    <div id="sito">
      <Navbar />
      <router-view />
      <Footer />
    </div>
    <div id="voicebot" v-if="currentAppMode != 'staging-v2'">
      <Bot />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import router from "@/router/index.js";
import Bot from "@/views/bot/Bot.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Bot,
  },
  mounted() {
    window.dotswitchTrigger = function (action, params) {
      switch (action) {
        case "to_internal_page":
          router.push(params.route);
          break;
        case "to_external_page":
          window.open(params.url, "_blank");
          break;
      }
    };
  },
  computed: {
    currentAppMode() {
      return process.env.VUE_APP_MODE;
    },
  },
};
</script>

<style lang="scss">
#fullpage-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/waves.png");
  background-color: $dotvocal-dark;
  background-repeat: no-repeat;
  background-position-y: 200px;
  background-size: contain;
  #sito {
    width: 75%;
  }
  #voicebot {
    width: 25%;
  }
}
@media (max-width: 991.98px) {
  #fullpage-container {
    position: relative;
    display: block;
    #sito {
      width: 100%;
      margin-bottom: 15vh;
      overflow-x: hidden;
    }
    #voicebot {
      width: 100%;
      height: 15%;
      position: fixed;
      bottom: 0;
      background-color: $dotvocal-dark;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
