<template>
  <div id="chi-siamo">
    <DvSection :title="$t('ABOUT_US.TITLE')">
      <div class="about-container-flex">
        <div class="about-dotvocal" v-html="$t('ABOUT_US.MAIN.DOTVOCAL')" />
        <div class="about-content" v-html="$t('ABOUT_US.MAIN.CONTENT')" />
      </div>
      <div class="about-cards-container">
        <div class="about-container-border">
          <div class="about-card">
            <div
              class="about-text-container"
              v-html="$t('ABOUT_US.MAIN.APPLICATION')"
            />
          </div>
          <div class="about-border" />
        </div>
        <div class="about-container-border">
          <div class="about-card">
            <div
              class="about-text-container"
              v-html="$t('ABOUT_US.MAIN.VOICE')"
            />
          </div>
          <div class="about-border" />
        </div>
        <div class="about-container-border">
          <div class="about-card">
            <div
              class="about-text-container"
              v-html="$t('ABOUT_US.MAIN.EXPERIENCE')"
            />
          </div>
          <div class="about-border" />
        </div>
      </div>
    </DvSection>
  </div>
</template>
<script>
import DvSection from "@/components/layout/DvSection.vue";
export default {
  components: {
    DvSection,
  },
};
</script>

<style lang="scss">
#chi-siamo {
  .about-container-flex {
    margin: 2rem;
    display: flex;
    justify-content: space-between;
    .about-dotvocal,
    .about-content {
      width: 45%;
    }
    .about-dotvocal {
      font-size: 1.4rem;
      font-weight: bold;
    }
    .about-content {
      transform: translate(0, -80px);
    }
  }
  .about-cards-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .about-container-border {
      display: flex;
      position: relative;
      margin-bottom: 1.5rem;
      .about-card,
      .about-border {
        width: 420px;
        height: 190px;
      }
      .about-card {
        border: $dotvocal-dark 1px solid;
        border-radius: 2rem;
        z-index: 5;
        padding: 1rem;
        background-color: white;
        display: flex;
        align-items: center;
        .about-text-container {
          font-size: 1.3rem;
          text-align: center;
        }
      }
      .about-border {
        border: #006fbf 1px solid;
        border-radius: 2rem;
        transform: translate(10px, -10px);
        position: absolute;
        z-index: 0;
      }
    }
  }
}
@media (max-width: 850px) {
  #chi-siamo {
    .about-container-flex,
    .about-cards-container {
      flex-direction: column;
      align-content: center;
    }
    .about-container-flex {
      .about-dotvocal,
      .about-content {
        width: 100% !important;
      }
      .about-content {
        transform: none !important;
      }
    }
    .about-cards-container {
      .about-container-border {
        margin-bottom: 2rem;
      }
    }
  }
}
@media (max-width: 450px) {
  #chi-siamo {
    .about-cards-container {
      .about-container-border {
        .about-card,
        .about-border {
          width: 320px;
          height: 190px;
        }
      }
    }
  }
}
</style>
