<template>
  <div id="privacy">
    <DvSection :title="'Legale'">
      <h5>
        {{ $t("PRIVACY.MAIN.SUBTITLE") }}
      </h5>
      <div class="preserve-newlines" v-html="$t('PRIVACY.MAIN.CONTENT')" />
    </DvSection>
  </div>
</template>

<script>
import DvSection from "@/components/layout/DvSection.vue";
export default {
  components: {
    DvSection,
  },
};
</script>
<style lang="scss" scoped>
h5 {
  font-weight: bold;
}
</style>
