import { I18N_LOCALE } from "@/common/constants";
import { createI18n } from "vue-i18n";

const messages = {
  it: require("./locales/it.yml"),
  en: require("./locales/en.yml"),
};

const i18n = createI18n({
  locale: I18N_LOCALE || "it",
  fallbackLocale: "it",
  //silentTranslationWarn: process.env.NODE_ENV === "production",
  messages,
});

export default i18n;
