<template>
  <Claim />
  <Product />
  <About />
  <Clients />
  <Partners />
  <Team />
  <Contacts />
</template>

<script>
import Claim from "@/views/home/sections/Claim.vue";
import About from "@/views/home/sections/About.vue";
import Clients from "@/views/home/sections/Clients.vue";
import Product from "@/views/home/sections/Product.vue";
import Partners from "@/views/home/sections/Partners.vue";
import Team from "@/views/home/sections/Team.vue";
import Contacts from "@/views/home/sections/Contacts.vue";

export default {
  name: "App",
  components: {
    Claim,
    About,
    Clients,
    Product,
    Partners,
    Team,
    Contacts,
  },
};
</script>
