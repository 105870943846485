<template>
  <div id="workProgress">
    <h1>Work in Progress</h1>
  </div>
</template>
<style lang="scss" scoped>
#workProgress {
  height: 80vh;
}
</style>
