<template>
  <div id="prodotto">
    <div class="product-section">
      <h1>Assistente Virtuale Multi Canale</h1>
      <div class="divider" />
      <div class="product-info">
        <div class="product-description">
          <p>
            Abbiamo sviluppato un Assistente Virtuale Multi Canale per aiutare
            le Aziende a costruire una Customer Journey sul canale telefonico,
            sul web e sulla piattaforme social e di messaggistica istantanea.
          </p>
        </div>
        <div class="product-logic">
          <p>
            Il nostro Assistente Virtuale Multicanale è integrabile con i più
            diffusi
            <b>TouchPoints</b>
            aziendali mediante l'utilizzo di
            <b>BOT,</b>
            che, interagendo in modo naturale con l'utente, automatizzano le
            operazioni di scambio di informazioni.
          </p>
          <p>
            <b>L'Esperienza Client</b>
            e può concludersi già attraverso il lavoro dei BOT, se soddisfatte
            le sue richieste, oppure può continuare attraverso il contatto con
            un Operatore in modo seamless, ossia senza alcuna interruzione
            dell'Esperienza in corso.
          </p>
          <p>
            L'Operatore potrà a sua volta disporre di tutte le informazioni
            scambiate tra il Cliente ed il BOT sino a quel momento, in modo da
            continuare in modo naturale e mirato la sua azione di supporto.
          </p>
        </div>
      </div>
    </div>
    <div class="benefits">
      <div class="benefits-company">
        <h3>Benefici per l'Azienda</h3>
        <ul>
          <li>
            <div class="content">
              Essere sempre in contatto con clienti e prospect
            </div>
          </li>
          <li>
            <div class="content">
              Migliorare i processi di pre e post vendita
            </div>
          </li>
          <li>
            <div class="content">
              Specializzare le competenze degli operatori
            </div>
          </li>
          <li>
            <div class="content">
              Disporre dei dati necessari per l'analisi della relazione e delle
              tendenze del mercato
            </div>
          </li>
          <li>
            <div class="content">Ottimizzare i flussi operativi e i costi</div>
          </li>
          <li>
            <div class="content">Massimizzare il ritorno di investimento</div>
          </li>
        </ul>
      </div>
      <div class="benefits-client">
        <h3>Benefici per il Cliente</h3>
        <ul>
          <li>
            <div class="content">
              Maggiore semplicità, accuratezza e velocità di interazione
            </div>
          </li>
          <li>
            <div class="content">Utilizzo di app/tools di uso quotidiano</div>
          </li>
          <li>
            <div class="content">
              Fiducia nella risoluzione in un solo contatto - "one stop solving"
            </div>
          </li>
          <li><div class="content">Disponibilità del servizio H.24</div></li>
        </ul>
      </div>
    </div>
    <div class="product-schema">
      <h1>Touchpoint aziendali</h1>
      <div class="divider" />
      <img src="@/assets/images/touchpoint.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#prodotto {
  margin: 2rem;
  .product-section {
    .product-info {
      display: flex;
      justify-content: space-between;
    }
  }

  ul {
    li {
      display: flex;
      align-items: center;
      .content {
        margin-left: 2rem;
      }
      &::before {
        content: "0" counter(number) " ";
        color: transparent;
        font-family: "Poppins";
        font-size: 3em;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $dotvocal-light;
        font-weight: bold;
      }

      counter-increment: number;
    }
  }

  .product-description {
    font-size: 1.4rem;
    font-weight: bold;
    padding-top: 2rem;
  }
  .product-description,
  .product-logic {
    width: calc(45% - 1rem);
  }
  .product-logic {
    transform: translate(0, -150px);
  }

  .divider {
    width: 30%;
  }

  .product-schema {
    text-align: center;
  }
  .product-schema img {
    width: 70%;
    margin-top: 1.5rem;
  }
  h1 {
    text-align: left;
    width: 35%;
  }
  .benefits {
    display: flex;
    margin-bottom: 2rem;
  }
  #dotswitch {
    #dotswitch-main-button {
      width: 200px;
    }
  }
  .benefits-company,
  .benefits-client {
    width: calc(50% - 2rem);
    h3 {
      margin-bottom: 1rem;
      text-align: center;
      font-weight: bold;
    }
    li {
      margin: 0;
      padding: 5px 0 5px 65px;
      list-style: none;
    }
  }

  @media (max-width: 991.98px) {
    .product-section,
    .product-info,
    .benefits {
      flex-direction: column;
    }
    h1 {
      width: 100%;
    }
    .product-description,
    .product-logic {
      width: 100%;
    }
    .product-logic {
      transform: none;
    }
    .benefits-company,
    .benefits-client {
      width: 100%;
    }
    .product-info,
    .product-schema,
    .benefits {
      width: 100% !important;
    }
    .product-schema img {
      width: 100%;
    }
    .benefits-company,
    .benefits-client {
      ul {
        padding-left: 1rem;
      }
      li {
        padding: 5px 0;
      }
    }
  }
}
</style>
