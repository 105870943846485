import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/home/Home.vue";
import Privacy from "@/views/privacy/Privacy.vue";
import Projects from "@/views/projects/Projects.vue";
import WorkProgress from "@/views/WorkProgress.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { splash: true },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/progetti-cofinanziati-2014-2020",
    name: "projects",
    component: Projects,
  },
  {
    path: "/work-in-progress",
    name: "WorkProgress",
    component: WorkProgress,
  },
  {
    path: "/bot2",
    name: "bot2",
    component: Home,
  },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const NAVBAR_GAP = 100;
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      let element = document.querySelector(to.hash);
      if (!element) return { top: 0 };
      let top = element.offsetTop - NAVBAR_GAP;

      return {
        top,
        //el: to.hash,
        //behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
