<template>
  <div class="bot-background">
    <div class="bot-border preserve-newlines" v-html="$t('BOT.MAIN.CONTENT')" />
  </div>
</template>

<style lang="scss" scoped>
.bot-background {
  padding: 1rem 2rem 0 2rem;
  border-radius: 1.5rem;
  background-color: transparent;
  box-shadow: 0 0 20px 1px rgba(254, 254, 254, 0.5) inset;
  backdrop-filter: blur(4px);
  border: solid 2px rgba(0, 176, 239, 0.6);
  height: 83vh;
  margin: 1em;
  position: fixed;
}
.bot-border {
  font-size: 2.3vh;
  text-align: center;
  overflow-y: auto;
  color: $text-color-white;
}
@media (max-width: 991.98px) {
  .bot-background {
    width: 95%;
    height: 10vh;
    position: relative;
  }
  .bot-border {
    display: none;
  }
}
@media (min-width: 991.99px) {
  .bot-border {
    height: 78vh;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 176, 239, 0.6);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $dotvocal-medium;
  }
}
</style>
