<template>
  <slot name="title">
    <h1>
      {{ title }}
    </h1>
    <div class="divider" />
  </slot>
  <slot name="default"></slot>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-left: 2rem;
  color: $dotvocal-dark;
  text-align: left;
}
.divider {
  width: 35%;
  border: solid 1px $dotvocal-dark;
  height: 0px;
  margin: 0 2rem;
}
</style>
