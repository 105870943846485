<template>
  <div id="claim">
    <div class="claim-box">
      <div
        id="claim-title"
        class="preserve-newlines"
        v-html="$t('CLAIM.MAIN.TITLE')"
      />
      <div
        id="claim-text"
        class="preserve-newlines"
        data-aos="fade-right"
        data-aos-once="true"
        style="transition-duration: 3000ms"
        v-html="$t('CLAIM.MAIN.CONTENT')"
      />
    </div>
  </div>
</template>

<style lang="scss">
#claim {
  height: 100vh;
  .claim-box {
    color: $text-color-white;
    position: absolute;
    top: 30vh;
    width: 75%;
    #claim-title,
    #claim-text {
      text-align: center;
      font-family: "Poppins";
      font-weight: 500;
    }
    #claim-title {
      font-size: 64px;
    }
    #claim-text {
      font-size: 20px;
      font-weight: bold;
    }
  }
  @media (max-width: 991.98px) {
    .claim-box {
      width: 100%;
      top: 20vh;
    }
  }
  @media (max-width: 768px) {
    .claim-box {
      #claim-title {
        font-size: 40px;
      }
    }
  }
  @media (max-width: 600px) {
    .claim-box {
      #claim-text {
        top: 30vh;
        font-size: 1em;
      }
    }
  }
}
</style>
