<template>
  <div id="progetti">
    <DvSection
      :title="`Progetti cofinanziati con le risorse del P.O.R. FESR Liguria 2014-2020 -
      Fondo Europeo di Sviluppo Regionale 2014-2020`"
    >
      <div class="text-progetti">
        <p class="programma">
          PROGRAMMA OPERATIVO REGIONALE 2014 - 2020
          <br />
          <br />
          Obiettivo “Investimenti in favore della crescita e dell'occupazione”
          <br />
          <br />
          COFINANZIATO DAL F.E.S.R. - FONDO EUROPEO DI SVILUPPO REGIONALE
          <br />
          <br />
          Asse 3 “COMPETITIVITA' DELLE IMPRESE (OT3)”
        </p>

        <h5>
          <a href="/docs/Elenco aiuti.pdf" target="_blank" rel="noopener">
            Elenco Contributi
          </a>
        </h5>
        <p>
          <b>
            Domanda di agevolazione Azione 3.1.1 “Aiuti per investimenti in
            macchinari, impianti e beni intangibili e accompagnamento dei
            processi di riorganizzazione e ristrutturazione aziendale”- Covid 19
            Adeguamento Processi produttivi delle PMI
          </b>
        </p>
        <p>
          Domanda di agevolazione Azione 3.1.1 “Aiuti per investimenti in
          macchinari, impianti e beni intangibili e accompagnamento dei processi
          di riorganizzazione e ristrutturazione aziendale”- Digitalizzazione
          delle micro, piccole e medie imprese
        </p>
        <p>
          Il progetto consiste nella progettazione e realizzazione di un sito in
          due lingue (italiano e inglese) con diverse pagine per la
          presentazione sintetica di alcune informazioni sull'azienda e sui
          prodotti e servizi proposti.
          <br />
          Inoltre la pagina delle news del sito ha permesso la pubblicazione
          automatica dei post sui principali social, in modo da creare un
          effetto moltiplicativo della comunicazione che consenta di raggiungere
          molti potenziali clienti.
          <br />
          L'investimento, effettuato interamente con risorse proprie
          dell'azienda, è in linea con i costi professionali di mercato ed è
          stato ammortizzato nell'attività dell'azienda, in quanto una sola
          vendita della Multicanalità Vocale o della Speech Analytics Suite la
          ripaga ampiamente.
        </p>
        <p>
          <b>
            Domanda di agevolazione Azione 3.1.1 “Aiuti per investimenti in
            macchinari, impianti e beni intangibili e accompagnamento dei
            processi di riorganizzazione e ristrutturazione aziendale”- Covid 19
            Adeguamento Processi produttivi delle PMI
          </b>
        </p>
        <p>
          L'intervento è stato suddiviso in due parti distinte che hanno
          obiettivi diversi, ma entrambi convergenti rispetto a garantire il
          rispetto delle misure di sicurezza e di distanziamento sociale
          nell'ufficio.
          <br />
          La prima parte riguarda l'acquisto di prodotti per l'igienizzazione e
          la sanificazione dell'ufficio e di dispositivi di protezione
          individuale come le mascherine e i guanti monouso.
          <br />
          In questo caso le misure adottate hanno lo scopo di minimizzare le
          possibilità di contagio e vanno naturalmente a garantire le misure di
          sicurezza all'interno dell'ufficio.
        </p>
        <p>
          La seconda parte, invece, è stato relativa all'acquisto di una
          piattaforma software per la gestione del lavoro agile e della
          collaborazione digitale. Questo strumento, assolutamente necessario
          per la riorganizzazione e la digitalizzazione dell'azienda, ha invece
          lo scopo di permettere una turnazione tra i dipendenti.
          <br />
          L'obiettivo è arrivare ad avere una turnazione del 50% del personale,
          in modo che la presenza fisica in ufficio sia praticamente dimezzata e
          di conseguenza si ottenga un maggiore distanziamento tra i dipendenti.
          In questo modo le possibilità di contagio vengono ulteriormente
          minimizzate, se non addirittura eliminate.
          <br />
          La piattaforma software è uno strumento che consente la totale
          operatività del personale da remoto, mettendo a disposizione in piena
          sicurezza tutte le informazioni e documenti necessari, nonché la
          possibilità di gestire progetti, riunioni e conversazioni. Le
          principali caratteristiche della piattaforma sono le seguenti:
        </p>
        <p>Profilo personale:</p>
        <ul>
          <li>aggiornamento del profilo / avatar /informazioni;</li>
          <li>
            gestione del flusso di attività personali - gestione della
            visualizzazione dei profili dei contatti;
          </li>
          <li>
            invio e revoca di una richiesta di connessione - accettazione o
            rifiuto delle richieste di connessione
          </li>
          <li>disconnessione dai contatti.</li>
        </ul>
        <p>
          Gestione delle notifiche Gestione delle task all'interno dei progetti:
        </p>
        <ul>
          <li>Da fare: compiti che verranno svolti in futuro;</li>
          <li>In corso: attività gestite in quel momento;</li>
          <li>
            In attesa: attività in attesa di una decisione o di una convalida,
            ovvero in attesa di una terza parte;
          </li>
          <li>
            fatto: compiti per i quali è terminato il lavoro. Discussioni in
            chat
          </li>
          <li>
            creazione di una conversazione con i tuoi contatti, dal gruppo alla
            persona specifica
          </li>
          <li>gestione dello stato sulla chat per non essere disturbato</li>
          <li>gestione dei contatti preferiti</li>
          <li>
            utilizzo della collaborazione con una serie di azioni: creazione di
            un evento, attività, condivisione di un collegamento o di un file,
            effettuare una domanda diretta o alzando la mano, ecc.
          </li>
          <li>gestione delle notifiche in tempo reale</li>
          <li>registrazione delle discussioni per invio e archiviazione</li>
        </ul>
        <p>Gestione dei documenti:</p>
        <ul>
          <li>gestione delle viste private e viste comuni</li>
          <li>condivisione volontaria e automatica</li>
          <li>archiviazione, condivisione e modifica sullo stesso documento</li>
          <li>
            controllo delle versioni e tracciabilità delle modifiche Gestione
            dei calendari
          </li>
          <li>
            creazione, modifica, esportazione e importazione di calendari
            personali, di gruppo o remoti
          </li>
          <li>
            pianificazione, creazione, modifica, esportazione e importazione di
            eventi
          </li>
          <li>gestione delle preferenze di comunicazione e condivisione</li>
        </ul>
        <p>
          <b>
            Domanda di agevolazione Azione 3.1.1 “Aiuti per investimenti in
            macchinari, impianti e beni intangibili e accompagnamento dei
            processi di riorganizzazione e ristrutturazione aziendale”-
            Digitalizzazione delle micro, piccole e medie imprese
          </b>
        </p>
        <p>
          L'intervento è stato finalizzato a fornire ai dipendenti gli strumenti
          necessari per lavorare facilmente in modalità remota di Smart Working.
          <br />
          Tutte le componenti individuate sono state finalizzate al
          miglioramento della rete interna dell'azienda, al fine di renderla
          fruibile da remoto in piena sicurezza, e alla fornitura ai dipendenti
          di strumenti adeguati per potenza di calcolo, capacità di memoria e
          performance in generale.
          <br />
          Per quanto riguarda la rete aziendale è stato individuato un firewall
          hardware di ultima generazione che consente la configurazione e la
          gestione degli accessi in piena autonomia aziendale e con il massimo
          livello di sicurezza.
          <br />
          I portatili e lo smartphone di ultima generazione individuati per il
          progetto vanno incontro alle esigenze specifiche dei vari dipendenti
          sulla base dei loro ruoli e compiti lavorativi nei processi aziendali.
          <br />
          L'installazione e configurazione del firewall e dei device digitali è
          stato operata direttamente dal personale dell'azienda che ha tutte le
          competenze tecniche per realizzare il progetto.
        </p>
      </div>
    </DvSection>
  </div>
</template>

<script>
import DvSection from "@/components/layout/DvSection.vue";
export default {
  components: {
    DvSection,
  },
};
</script>

<style lang="scss" scoped>
#progetti {
  h5 {
    margin: 2rem;
    text-align: center;
    font-weight: bold;
  }
  .programma {
    text-align: center;
    font-weight: bold;
  }
}
</style>
