<template>
  <div id="contatti">
    <DvSection :title="$t('CONTACT_US.TITLE')">
      <div class="location-container-flex">
        <div class="location">
          <div
            class="location-info"
            data-aos="fade-right"
            data-aos-once="true"
            style="transition-duration: 2000ms"
          >
            <div class="contact-subtitle">{{ $t("CONTACT_US.SUBTITLE") }}</div>
            <div
              class="location-description"
              v-html="$t('CONTACT_US.MAIN.CONTENT')"
            />
          </div>
          <div class="map">
            <iframe
              data-aos="fade-down"
              data-aos-once="true"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11400.318144395456!2d8.9292129!3d44.4110136!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x632e5252de6a11dc!2sDotvocal%20Innovation%20Srl!5e0!3m2!1sit!2sit!4v1651660208603!5m2!1sit!2sit"
              width="100%"
              height="350"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="contacts">
          <div
            class="contact-info"
            data-aos="fade-left"
            data-aos-once="true"
            style="transition-duration: 2000ms"
          >
            <div class="contact-subtitle">Contattaci</div>
            <div class="phone-info">
              <p>
                Siamo aperti dal Lunedì al Venerdì
                <br />
                dalle 9:30 alle 12:30 e dalle 14:30 alle 17:30
              </p>
              <span>Se vuoi inviarci una mail</span>
              <br />
              <span>
                <b>
                  <a href="mailto:info@dotvocal.com">info@dotvocal.com</a>
                </b>
              </span>
              <br />
              <div class="phone-number">
                <span>{{ $t("CONTACT_US.MAIN.PHONE.TITLE") }}</span>
                <b>
                  <div v-html="$t('CONTACT_US.MAIN.PHONE.NUMBER')" />
                </b>
              </div>
            </div>
            <div id="social">
              <a href="https://www.facebook.com/dotvocal/" target="blank">
                <img alt="Facebook" src="@/assets/images/facebook-dark.png" />
              </a>
              <a
                href="https://www.linkedin.com/company/1744090/admin/"
                target="blank"
              >
                <img alt="Linkedin" src="@/assets/images/linkedin-dark.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </DvSection>
  </div>
</template>
<script>
import DvSection from "@/components/layout/DvSection.vue";
export default {
  components: {
    DvSection,
  },
};
</script>

<style lang="scss" scoped>
#contatti {
  .location-container-flex {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    height: 100%;
    margin-bottom: 1.5rem;
    .location,
    .contacts {
      width: calc(45% - 3rem);
    }
    .contact-subtitle {
      font-size: 1.4rem;
      font-weight: bold;
      margin: 2rem 0;
    }
    .location-description,
    .map,
    .phone-info,
    .phone-number {
      margin: 2rem 0;
    }
  }
  iframe {
    transition-duration: 2000ms;
  }
  @media (max-width: 850px) {
    .location-container-flex {
      flex-direction: column;
      .location,
      .contacts {
        width: 100%;
      }
    }
  }
}
</style>
