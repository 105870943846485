<template>
  <div id="clienti">
    <div class="clients-section">
      <DvSection :title="$t('CLIENTS.TITLE')">
        <div class="clients-container-flex">
          <div class="clients-info">
            <div
              class="clients-info-claim"
              v-html="$t('CLIENTS.MAIN.CONTENTBG')"
            />
            <div
              class="clients-info-description"
              v-html="$t('CLIENTS.MAIN.CONTENT')"
            />
          </div>
          <div class="clients-info-list" v-html="$t('CLIENTS.MAIN.LIST')" />
        </div>
      </DvSection>
      <DvCarousel :images="clientsSlider"></DvCarousel>
    </div>
  </div>
</template>
<script>
import DvSection from "@/components/layout/DvSection.vue";
import DvCarousel from "@/components/layout/DvCarousel.vue";

export default {
  name: "Clients",
  data() {
    return {
      clientsSlider: [
        {
          name: require("@/assets/images/carousel/clients/logo-alfa-romeo.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-casa-salute.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-centro-paghe.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-eni.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-happily.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-lancia.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-localeyes.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-magneti.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-manetti-roberts.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-pinklady.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-rch.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-unicredit.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-verti.png"),
        },
        {
          name: require("@/assets/images/carousel/clients/logo-wurth.png"),
        },
      ],
    };
  },
  components: {
    DvSection,
    DvCarousel,
  },
};
</script>

<style lang="scss" scoped>
#clienti {
  .clients-section {
    background-color: $dotvocal-dark;
    .clients-container-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2rem;
      .clients-info,
      .clients-info-list {
        width: calc(45% - 1rem);
      }
      .clients-info-claim {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }
    }
  }
  @media (max-width: 850px) {
    .clients-section {
      .clients-container-flex {
        flex-direction: column;
        .clients-info,
        .clients-info-list {
          width: 100%;
        }
        .clients-info-list {
          padding-top: 2rem;
        }
      }
    }
  }
}
</style>
