<template>
  <div id="not-found">
    <center>
      <h1>Pagina non trovata!</h1>
      <p>
        <a href="/">Torna alla home</a>
      </p>
    </center>
  </div>
</template>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
