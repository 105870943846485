<template>
  <div id="footer">
    <div class="footer-section">
      <div class="footer-container-flex">
        <div>
          <ul class="footer-info">
            <li>
              Dotvocal
              <small><sup>©</sup></small>
              è un marchio registrato di proprietà di Dotvocal Innovation S.r.l.
            </li>
            <li>Piazza Pinelli 1/7, 16124 Genova</li>
            <li>P. IVA: IT02650420991</li>
          </ul>
        </div>
        <div>
          <ul class="other-pages">
            <li>
              <router-link to="/privacy">{{ $t("PRIVACY.TITLE") }}</router-link>
            </li>
            <li>
              <a
                href="/docs/Bilancio di Sostenibilità Dotvocal Innovation 2022.pdf"
                target="_blank"
                rel="noopener"
              >
                Bilancio di Sostenibilità
              </a>
            </li>
            <li>
              <a
                href="/docs/Relazione 2022 Dotvocal Innovation Srl Società Benefit.pdf"
                target="_blank"
                rel="noopener"
              >
                Relazione Annuale
              </a>
            </li>
          </ul>
        </div>
        <div>
          <div class="loghi">
            <img alt="Marchi" src="@/assets/images/marchi-filse.png" />
          </div>
          <p>
            <router-link to="/progetti-cofinanziati-2014-2020">
              Progetti cofinanziati con le risorse del P.O.R. FESR Liguria
              2014-2020 - Fondo Europeo di Sviluppo Regionale 2014-2020
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#footer {
  background-color: white;
  .footer-section {
    background-color: $dotvocal-dark;
    color: $text-color-white;
    font-size: $font-size;
    padding-top: 5px;
    padding-bottom: 5px;
    .footer-container-flex {
      margin-top: 2rem;
      display: flex;
      flex-wrap: wrap;
      div {
        width: 33%;
        ul {
          list-style: none;
        }
        ul.footer-info {
          li {
            margin-bottom: 1.5em;
          }
        }
        ul.other-pages {
          li {
            margin-bottom: 1rem;
          }
        }
      }
    }
    p {
      margin: 0 1rem 0 1rem;
    }
    a {
      color: $text-color-white;
      text-decoration: underline !important;
    }
  }
  .loghi {
    width: 100% !important;
    img {
      margin-bottom: 1.5em;
      width: 100%;
    }
  }
  @media (max-width: 700px) {
    .footer-container-flex {
      flex-direction: column;
      div {
        width: 100% !important;
      }
      .loghi img {
        margin: 2rem;
        width: 80%;
      }
    }
  }
}
</style>
