<template>
  <div id="team">
    <DvSection :title="$t('TEAM.TITLE')">
      <div class="team-container-flex">
        <div class="team-info">
          <div class="team-description">
            Il nostro Team è composto da
            <b>10</b>
            persone ed è cresciuto negli anni per mantenere la capacità di
            <b>rispondere immediatamente alle esigenze</b>
            di una clientela e di un mercato in continua evoluzione.
            <br />
            <br />
          </div>
          <div class="team-contacts">
            Ti interessa lavorare con noi?
            <br />
            Invia un curriculum a:
            <b>
              <a href="mailto:personale@dotvocal.com">personale@dotvocal.com</a>
            </b>
          </div>
        </div>
        <div class="team-image">
          <img src="@/assets/images/newteam.png" />
          <div class="team-image-border"></div>
        </div>
      </div>
    </DvSection>
  </div>
</template>
<script>
import DvSection from "@/components/layout/DvSection.vue";
export default {
  components: {
    DvSection,
  },
};
</script>

<style lang="scss" scoped>
#team {
  .team-container-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem;
    color: $dotvocal-dark;
    a {
      color: $dotvocal-dark;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .team-info,
    .team-image {
      width: calc(50% - 2rem);
    }
    .team-info {
      .team-description {
        font-size: 20px;
      }
    }
    .team-image img,
    .team-image-border {
      width: 500px;
      height: 330px;
    }
    .team-image {
      justify-content: center;
      display: flex;
      transform: translate(0, -80px);
      img {
        position: relative;
        z-index: 5;
      }
      .team-image-border {
        border: #006fbf 1px solid;
        border-radius: 2rem;
        transform: translate(20px, -10px);
        position: absolute;
        z-index: 0;
      }
    }
  }
  @media (max-width: 1362px) {
    .team-container-flex {
      flex-direction: column;
      .team-info,
      .team-image {
        width: 100%;
      }
      .team-info {
        .team-description {
          margin-top: 2rem;
        }
      }
      .team-image {
        margin-top: 2rem;
        transform: none;
      }
    }
  }
  @media (max-width: 600px) {
    .team-container-flex {
      .team-image img,
      .team-image-border {
        width: 300px;
        height: 200px;
      }
    }
  }
}
</style>
