<template>
  <div
    id="navbar"
    :class="{
      splash: $route.meta.splash,
      top: scrollPosition < 50,
    }"
  >
    <router-link style="height: 100%" :to="{ name: 'home', hash: '' }">
      <img
        id="dotvocal-icon"
        alt="Dotvocal"
        src="@/assets/images/logo-dotvocal.png"
      />
    </router-link>
    <div class="navrouter">
      <ul>
        <li>
          <router-link :to="{ name: 'home', hash: '#prodotto' }">
            {{ $t("PRODUCT.TITLE_NAVBAR") }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home', hash: '#chi-siamo' }">
            {{ $t("ABOUT_US.TITLE") }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home', hash: '#clienti' }">
            {{ $t("CLIENTS.TITLE_NAVBAR") }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home', hash: '#partner' }">
            {{ $t("PARTNERS.TITLE_NAVBAR") }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home', hash: '#team' }">
            {{ $t("TEAM.TITLE_NAVBAR") }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home', hash: '#contatti' }">
            {{ $t("CONTACT_US.TITLE") }}
          </router-link>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/dotvocalsrl/posts/?feedView=all"
            target="blank"
          >
            News
          </a>
        </li>
      </ul>
      <div id="social">
        <a href="https://www.facebook.com/dotvocal/" target="blank">
          <img alt="Facebook" src="@/assets/images/facebook-light.png" />
        </a>
        <a
          href="https://www.linkedin.com/company/1744090/admin/"
          target="blank"
        >
          <img alt="Linkedin" src="@/assets/images/linkedin-light.png" />
        </a>
      </div>
    </div>
    <div id="sidemenu">
      <button
        class="sidemenu__btn"
        @click="flockScroll()"
        :class="{ active: navOpen }"
      >
        <span class="top"></span>
        <span class="mid"></span>
        <span class="bottom"></span>
      </button>
      <Transition name="translateX">
        <nav v-show="navOpen">
          <div class="sidemenu__wrapper">
            <ul class="sidemenu__list">
              <li>
                <router-link :to="{ name: 'home', hash: '#prodotto' }">
                  {{ $t("PRODUCT.TITLE_NAVBAR") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'home', hash: '#chi-siamo' }">
                  {{ $t("ABOUT_US.TITLE") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'home', hash: '#clienti' }">
                  {{ $t("CLIENTS.TITLE_NAVBAR") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'home', hash: '#partner' }">
                  {{ $t("PARTNERS.TITLE_NAVBAR") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'home', hash: '#team' }">
                  {{ $t("TEAM.TITLE_NAVBAR") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'home', hash: '#contatti' }">
                  {{ $t("CONTACT_US.TITLE") }}
                </router-link>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/dotvocalsrl/posts/?feedView=all"
                  target="blank"
                >
                  News
                </a>
              </li>
              <li>
                <a
                  class="facebook"
                  href="https://www.facebook.com/dotvocal/"
                  target="blank"
                >
                  <img
                    alt="Facebook"
                    src="@/assets/images/facebook-light.png"
                  />
                </a>
              </li>
              <li>
                <a
                  class="linkedin"
                  href="https://www.linkedin.com/company/1744090/admin/"
                  target="blank"
                >
                  <img
                    alt="Linkedin"
                    src="@/assets/images/linkedin-light.png"
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      navOpen: false,
      scrollPosition: null,
    };
  },
  methods: {
    flockScroll() {
      this.navOpen = !this.navOpen;
      document.body.classList.toggle("lock-scroll");
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    $route() {
      this.navOpen = false;
    },
  },
};
</script>

<style lang="scss">
#navbar {
  background-color: $dotvocal-dark;
  align-items: center;
  height: $navbar-height;
  position: fixed;
  display: flex;
  z-index: 10;
  width: 75%;
  transition: background-color 1s, padding-right 0s, box-shadow 1s,
    padding-right 0s;
  border-bottom: solid 0.5px #152238;
  #dotvocal-text {
    display: none;
  }
  #sidemenu {
    display: none;
  }

  a {
    color: $text-color-white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.splash.top {
    background-color: transparent;
    border: none;
    box-shadow: none;
    align-items: center;
    #dotvocal-text {
      display: block;
    }
    #sidemenu {
      display: none;
    }
  }
  ul {
    margin: 0 1rem 0 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin: 0 0.5rem 0 0.5rem;
    }
  }
}
.navrouter {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

#social {
  img {
    margin-right: 1rem;
    width: 30px;
    height: 30px;
  }
}

#dotvocal-icon {
  width: 70%;
  margin-top: 1rem;
  margin-left: 1rem;
}

.lock-scroll {
  overflow: hidden;
}

// MEDIA

@media (min-width: 991.99px) and (max-width: 1440px) {
  #sidemenu {
    nav {
      width: 75%;
    }
  }
}
@media (max-width: 991.98px) {
  #sidemenu {
    nav {
      width: 100%;
    }
  }
}
@media (max-width: 1440px) {
  .navrouter {
    display: none;
  }
  #sidemenu {
    display: block !important;
    nav {
      background: $dotvocal-dark;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
    }

    .sidemenu {
      &__btn {
        display: block;
        width: 50px;
        height: 50px;
        background: transparent;
        border: none;
        position: absolute;
        top: 20%;
        right: 0;
        z-index: 1;
        appearance: none;
        cursor: pointer;
        outline: none;

        span {
          display: block;
          width: 20px;
          height: 2px;
          margin: auto;
          background: white;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transition: all 0.4s ease;

          &.top {
            transform: translateY(-8px);
          }

          &.bottom {
            transform: translateY(8px);
          }
        }
        &.active {
          .top {
            transform: rotate(-45deg);
          }
          .mid {
            transform: translateX(-20px) rotate(360deg);
            opacity: 0;
          }
          .bottom {
            transform: rotate(45deg);
          }
        }
      }

      &__wrapper {
        padding-top: 65px;
      }

      &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: block;
          text-align: center;
          border-top: 1px solid white;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          margin: 0;
          font-size: 1.2em;
        }
        li:last-child {
          border-bottom: 1px solid white;
        }
      }

      &__item {
        a {
          text-decoration: none;
          line-height: 1.6em;
          font-size: 1.6em;
          padding: 0.5em;
          display: block;
          color: white;
          transition: 0.4s ease;

          &:hover {
            background: lightgrey;
            color: dimgrey;
          }
        }
      }
    }
  }

  .translateX-enter {
    transform: translateX(-200px);
    opacity: 0;
  }

  .translateX-enter-active,
  .translateX-leave-active {
    transform-origin: top left 0;
    transition: 0.2s ease;
  }

  .translateX-leave-to {
    transform: translateX(-200px);
    opacity: 0;
  }
}

@media (max-width: 991.98px) {
  nav {
    height: 86vh !important;
  }
}

@media (max-width: 500px) {
  #dotvocal-icon {
    width: 60%;
    margin-top: 1.5rem;
  }
}
</style>
