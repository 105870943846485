<template>
  <div id="partner">
    <div class="partner-section">
      <DvSection :title="$t('PARTNERS.TITLE')">
        <div class="partner-container-flex">
          <div class="partner-info" v-html="$t('PARTNERS.MAIN.CONTENT')" />
          <div
            class="partner-info"
            v-html="$t('PARTNERS.MAIN.COLLABORATION')"
          />
          <div class="partner-info" v-html="$t('PARTNERS.MAIN.SOLUTION')" />
        </div>
      </DvSection>
      <DvCarousel :images="partnerSlider" :autoplay="4000"></DvCarousel>
    </div>
  </div>
</template>

<script>
import DvSection from "@/components/layout/DvSection.vue";
import DvCarousel from "@/components/layout/DvCarousel.vue";

export default {
  name: "Partner",
  data() {
    return {
      partnerSlider: [
        {
          name: require("@/assets/images/carousel/partners/logo-amazon-alexa.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-awhy.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-google-speech-api.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-ifm.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-instant.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-loquendo.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-nuance.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-oberon.png"),
        },
        {
          name: require("@/assets/images/carousel/partners/logo-spitch.png"),
        },
      ],
    };
  },
  components: {
    DvSection,
    DvCarousel,
  },
};
</script>
<style lang="scss" scoped>
.partner-container-flex {
  display: flex;
  justify-content: space-between;
  margin: 2rem;
}
.partner-info {
  width: calc(33% - 2rem);
}
@media (max-width: 850px) {
  .partner-container-flex {
    flex-direction: column;
  }
  .partner-info {
    width: 100%;
    margin-bottom: 2rem;
  }
}
</style>
